import RouterPage from "./My Components/FrontPage/RouterPage";

function App() {
  return (
    <div>
      <RouterPage />
    </div>
  );
}
export default App;
